@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,stat200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* { font-family: 'Poppins', sans-serif; }


html {
  height: auto !important;
}

body {
  margin: 0;
  padding: 0;
  background: transparent !important;
  font-family: 'Poppins', sans-serif;
}

.copyright {
  position:fixed;
  z-index: 5;
  bottom: 5px;
  left: 50%;
  transform: translateX(-48%);
}

.login {
  position: absolute;
  box-shadow: 0px 10px 30px black;
  top: 30vh;
  left: 50vw;
  width: 400px;
  margin-left: -200px;
  height: 150px;
  background: white;
}

.authpage {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  /* background-color: #4b66ac; */
  /* background: radial-gradient(50% 50% at 50% 50%, #4b66ac 0%, #3F568F 100%);  */
  background: rgb(238,184,210);
  background: linear-gradient(135deg, rgba(238,184,210,1) 0%, rgba(173,223,224,1) 100%);
}

.login-modal {
  background-color: aliceblue;
  padding: 40px;
  border: 0 solid white;
  border-radius: 20px;
  margin-top: 5vw;
  -webkit-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
}
.invoice-modal {
  background-color: aliceblue;
  padding: 10px;
  border: 0 solid white;
  border-radius: 20px;
  margin: 30px;
  max-height: 95vh;
  overflow-y: scroll;
  -webkit-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
}

.comfirm-modal {
  width: 300px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: aliceblue;
  padding: 20px;
  border: 0 solid white;
  border-radius: 20px;
  margin: 30px;
  overflow: hidden;
  -webkit-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-apple {
  width: 80vw;
  max-width: 600px;
  background-color: aliceblue;
  padding: 40px;
  border: 0 solid white;
  border-radius: 20px;
  margin: 20vh auto;
  box-shadow: 0px 15px 38px 0px rgb(0 0 0 / 49%);
}

.modal-tt {
  background-color: aliceblue;
  padding: 40px;
  border: 0 solid white;
  border-radius: 20px;
  position: absolute;
  left: 0;
  margin: 5vw auto;
  width: 90vw;
  min-width: 300px;
  max-height: 95vh;
  left:50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  overflow: scroll;
}

.modal-tt-doctor {
  background-color: aliceblue;
  padding: 40px;
  border: 0 solid white;
  border-radius: 20px;
  position: absolute;
  left: 0;
  margin: 3vw auto;
  width: 90vw;
  height: 90vh;
  /* min-width: 300px; */
  overflow-y: auto;
  left:50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.49);
}

.logo-block {
  color: white;
  padding-left: 50%;
}

.logo-container{
  display: flex;
  margin: 5vh 0px 0px;
  transform: translateX(-105px);
}

.logo-admin{
  max-width: 300px;
  margin-top: 50px;
  transform: translateX(-49%);
}

.logo-consult-form{
  display: flex;
  /* margin: 10vh 0px 0px; */
  transform: translateX(-50%);
  max-width: 280px;
  margin-top: 3vh;
}

.time-btn{
  background-color: #FCFBFD;
  margin: 5px 10px;
  width: 80px;
}

.hidden {
  display: none;
}

.inlineCal {
  display: inline-flex;
  max-width: 400px
}

.tt_title {
  min-width: 300px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 0px solid #fff;
  box-shadow: 0px 10px 30px black;
}

.avatar {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 0 0 10px;
}

.docfile {
  width: 140px;
  text-align: center;
  margin:20px;
  display: inline-block;
}

.imgfile {
  height: auto;
  max-height: 120px;
  margin: 0 auto;
}

.consult-form {
  width: 100%;
}

.cons-input {
  background-color: #FCFBFD;
  -webkit-box-shadow: 0px 4px 27px -20px rgba(66, 68, 90, 1);
  -moz-box-shadow:    0px 4px 27px -20px rgba(66, 68, 90, 1);
  box-shadow:         0px 4px 27px -20px rgba(66, 68, 90, 1);
}

.cons-radio {
  width: 45%;
  text-align: left;
  
}

.login-pwa-input {
  background-color: #fff;
  /* border: solid 1px #fff; */
  border-radius: 10px;
  text-align: center !important;
  -webkit-box-shadow: 0px 4px 27px -20px rgba(66, 68, 90, 1);
  -moz-box-shadow:    0px 4px 27px -20px rgba(66, 68, 90, 1);
  box-shadow:         0px 4px 27px -20px rgba(66, 68, 90, 1);
}
#password {text-align: center;}
#passwordConf {text-align: center;}

#email {text-align: center;}
#phone {text-align: center;}
#lastname {text-align: center;}
#firstname {text-align: center;}
.inp-center{text-align: center;}

.social-reg {
  width: 140px;
  height: 44px;
  background-color: #fff;
  border: #fff 1px solid;
  border-radius: 18px;
}

.user-menu {
  position: fixed;
  z-index: 9999;
  width: 75vw;
  max-width: 400px;
  padding: 15px 15px;

  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 25px;
  text-align: center;
  -webkit-box-shadow: 0px 4px 35px -15px rgba(66, 68, 90, 1);
  -moz-box-shadow:    0px 4px 35px -15px rgba(66, 68, 90, 1);
  box-shadow:         0px 4px 35px -15px rgba(66, 68, 90, 1);

  left: 50%;
  bottom: -10px;
  transform: translate(-50%, -50%);
}

.beta-button {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 90px;
  max-width: 90px;
  padding: 0;
  margin: 0;

  background: none;
}

.shadow {
  -webkit-box-shadow: 0px 4px 35px -15px rgba(66, 68, 90, 1);
  -moz-box-shadow:    0px 4px 35px -15px rgba(66, 68, 90, 1);
  box-shadow:         0px 4px 35px -15px rgba(66, 68, 90, 1);  
}

.comm-icon {
  width: 26px;
  height: 26px;
  margin-bottom: -5px;
  color: #dfbd63;
  cursor: pointer;
}

.c-button {
  top: 15px;
  left: 50%;
  transform: translateX(-51%);
}

.doc-ava {
  /* margin-left: 50%; */
  height: 190px;
  display: inline-block;
  margin: 0 30px;
  /* transform: translate(-50%, 0%); */
}

.dash-page {
  background: linear-gradient(135deg, rgba(238,184,210,1) 0%, rgba(173,223,224,1) 100%);
}

.dash-block {
  border: 0px white solid;
  border-radius: 20px;
  background-color: aliceblue;
  padding: 30px;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;

  -webkit-box-shadow: 0px 4px 27px -20px rgba(66, 68, 90, 1);
  -moz-box-shadow:    0px 4px 27px -20px rgba(66, 68, 90, 1);
  box-shadow:         0px 4px 27px -20px rgba(66, 68, 90, 1);  
}

.qr-block {
  margin-top: 30px;
}

.points {
  font-size: 44px;
  padding-top: 10px;
}

.input-blank {
  border: 0;
  width: 100%;
}
.input-blank-fit {
  border: 0;
}
.up-photo {
  cursor: pointer;
  width: 96px;
  height: 96px;
  color: lightgray;
  border: 2px solid lightgray;
  border-radius: 20px;
  padding: 20px 18px 20px 22px;
  margin: 10px;
}

.passed-days {
  border: 0px #fff solid;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;  
}

.day0 {
  background-color: #fff;
}
.day1 {
  background-color: aquamarine;
}
.day2 {
  background-color: rgb(255, 236, 127);
}
.day3 {
  background-color: rgb(255, 127, 165);
}


/* Booking Widget */
.book-widget {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

.scroll-none {
  scrollbar-width: none;
}
.scroll-none::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.MuiButton-root {
  /* border-radius: 0 !important; */
}


/* PARTNER */
.partner-ms {
  background: #AA4037;
}

.partner-top-menu {
  padding: 3vh 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partner-count {
  width: 100%;
  text-align: center;
}

.input-partner {
  background: #FCFBFD;
  border-radius: 8px;
}

.chkbx {
  /* background: #fff; */
  background: url(http://health.sy-way.com/static/check_bg.png) center center no-repeat
}


/* scroll for time */ 
.timeslots { 
  max-height: 250px;
  overflow-Y: scroll;
  overflow-x: hidden;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.timeslots::-webkit-scrollbar {
  width: 4px;               /* ширина всей полосы прокрутки */
}

.timeslots::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);        /* цвет зоны отслеживания */
}

.timeslots::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);    /* цвет бегунка */
  border-radius: 20px;       /* округлось бегунка */
  border: 0px solid rgba(0, 0, 0, 0);  /* отступ вокруг бегунка */
}




/* OVERWRITES */
.rbc-events-container {
  margin-right: 0 !important;
}

.rbc-event {
  border: 2px white solid !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 200 !important;
  text-align: center !important;
  padding-top: 4px !important;
}