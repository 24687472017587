.custom__modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.212);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal__content {
  /* overflow-y: auto; */
  max-height: 95vh;
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
}

.modal__content::-webkit-scrollbar {
  width: 12px !important;
}
